import Sticky from 'sticky-js';

export default class StickyEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.sticky')) {
      return false;
    }
    let sticky = new Sticky('[data-sticky]', {});
  }
}
