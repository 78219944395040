import smoothScroll from 'smooth-scroll';
import Rellax from 'rellax';

export default class AboutPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.AboutPage')) return false;

    const hovers = document.querySelectorAll('.js-hoverHide');
    const tdModals = document.querySelectorAll('.m_tdModal');
    const tdModalPlus = document.querySelectorAll('.m_tdModalPlus');

    for (let i = 0; i < hovers.length; i++) {
      hovers[i].addEventListener('mouseenter', (e) => {
        e.currentTarget.querySelector('.m_tdModal').classList.add('Hover');
        window.addEventListener('touchend', AboutProfileHide);
      });

      hovers[i].addEventListener('mouseleave', (e) => {
        e.currentTarget.querySelector('.m_tdModal').classList.remove('Hover');
      });
    }

    const AboutProfileHide = () => {
      window.removeEventListener('touchend', AboutProfileHide);
      const A = document.querySelectorAll('.m_tdModal.Hover');
      for (let i = 0; i < A.length; i++) {
        A[i].classList.remove('Hover');
      }
      return false;
    };

    for (let i = 0; i < tdModalPlus.length; i++) {
      tdModalPlus[i].addEventListener('click', (e) => {
        e.currentTarget.nextElementSibling.classList.add('Hover');
        window.addEventListener('touchend', AboutProfileHide);
      });
    }
    for (let i = 0; i < tdModals.length; i++) {
      tdModals[i].addEventListener('click', (e) => {
        e.currentTarget.classList.remove('Hover');
      });
    }
    for (let i = 0; i < tdModals.length; i++) {
      tdModals[i].addEventListener('mouseleave', (e) => {
        e.currentTarget.classList.remove('Hover');
      });
    }

    var defaultQueryList = {
      mobile: matchMedia('(max-width: 768px'),
      pc: matchMedia('(min-width: 769px)'),
    };

    function routingByBreakpoint(sourceList) {
      for (var i = 0, l = sourceList.length; i < l; i++) {
        var source = sourceList[i];
        if (!(source.dataset.query && defaultQueryList[source.dataset.query] && source.dataset.src))
          continue;
        if (source.dataset.query === 'mobile' && window.innerWidth > 768) {
          continue;
        }
        if (source.dataset.query === 'pc' && window.innerWidth < 768) {
          continue;
        }
        var newSource = document.createElement('source');
        newSource.src = source.dataset.src;
        source.parentElement.appendChild(newSource);
      }
    }
    var elemList = document.getElementsByClassName('routingByBreakpoint');

    for (var i = 0, l = elemList.length; i < l; i++) {
      var sourceList = elemList[i].getElementsByTagName('source');
      routingByBreakpoint(sourceList);
    }

    var rellax = new Rellax('.l_rellax', {});
    let smoothScrollObj = document.getElementsByClassName('js-smoothScroll');
    let option = {
      speed: 10,
      durationMax: 1000,
      durationMin: 800,
      clip: true,
      offset: 0,
      easing: 'easeInOutQuint',
      updateURL: false,
      popstate: false,
    };
    for (let i = 0; i < smoothScrollObj.length; i++) {
      smoothScrollObj[i].addEventListener('click', function (e) {
        let scroll = new smoothScroll('a[href*="#"]', option);
      });
    }

    const VideoCover = document.querySelector('.AboutPageVideoCover');
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 50) {
        VideoCover.classList.add('Act');
      } else {
        VideoCover.classList.remove('Act');
      }
    });
  }
}
