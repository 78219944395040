import imagesLoaded from 'imagesloaded';

import Rellax from 'rellax';

export default class PlayerPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.PlayerPage')) return false;

    var rellax = new Rellax('.l_rellax', {
      breakpoints: [375, 786, 787],
    });

    const PlayerProfileButtons = document.querySelectorAll('.PlayerProfile');
    const PlayerProfileBox = document.querySelector('.PlayerProfileBox');
    for (let i = 0; i < PlayerProfileButtons.length; i++) {
      PlayerProfileButtons[i].addEventListener('mouseover', () => {
        PlayerProfileBox.classList.add('Act');
        window.addEventListener('touchend', PlayerProfileHide);
      });
      PlayerProfileButtons[i].addEventListener('click', () => {
        PlayerProfileBox.classList.add('Act');
      });
    }
    PlayerProfileBox.addEventListener('mouseleave', () => {
      PlayerProfileBox.classList.remove('Act');
    });
    PlayerProfileBox.addEventListener('click', () => {
      PlayerProfileBox.classList.remove('Act');
    });

    const PlayerProfileHide = () => {
      window.removeEventListener('touchend', PlayerProfileHide);
      PlayerProfileBox.classList.remove('Act');
      return false;
    };

    const PlayerHead = document.querySelector('.PlayerHead');
    const PlayerBgBar = document.querySelector('.PlayerBgBar');
    imagesLoaded(PlayerHead, () => {
      PlayerBgBar.style.height = PlayerHead.clientHeight + 'px';
    });
  }
}
