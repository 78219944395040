export default class EntryPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (document.querySelector('.EntryPage')) {
      var url = location.search;
      const DoSelect = document.getElementById('do-select');
      let options = DoSelect.options;
      // console.log(DoSelect);
      switch (url) {
        case '?type=0':
          options[0].selected = true;
          break;
        case '?type=1':
          options[1].selected = true;
          break;
        case '?type=2':
          options[2].selected = true;
          break;
        case '?type=3':
          options[3].selected = true;
          break;
      }
      let select = document.getElementById('do-job');
      let yearWrap = document.getElementById('do-yearWrap');
      let year = document.getElementById('do-year');
      let yearOptions = year.options;
      select.addEventListener(`change`, () => {
        // alert(select.value);
        if (select.value === '0' || select.value === '1') {
          yearWrap.classList.remove('Hide');
        } else {
          yearWrap.classList.add('Hide');
          year[0].selected = true;
        }
      });
    }
    if (document.getElementById('check1')) {
      const check = document.getElementById('check1');
      const formSubmit = document.querySelector('.m_formSubmit');
      check.addEventListener('change', () => {
        // console.log(check.checked);
        if (check.checked) {
          formSubmit.disabled = false;
        } else {
          formSubmit.disabled = true;
        }
      });
    }
  }
}
