import smoothScroll from 'smooth-scroll';
export default class RecruitMentPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.RecruitMentPage')) return false;
    let smoothScrollObj = document.getElementsByClassName('js-smoothScroll');
    let option = {
      speed: 10,
      durationMax: 1000,
      durationMin: 800,
      clip: true,
      offset: 0,
      easing: 'easeInOutQuint',
      updateURL: false,
      popstate: false,
    };
    for (let i = 0; i < smoothScrollObj.length; i++) {
      smoothScrollObj[i].addEventListener('click', function (e) {
        let scroll = new smoothScroll('a[href*="#"]', option);
      });
    }
  }
}
