const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

export default class MenuEvent {
  constructor() {
    this.init();
  }
  init() {
    const Header = document.querySelector('.p_header');
    const Logo = document.querySelector('.p_headerLogo');
    const Nav = document.querySelector('.p_headerNav');
    const NavSub = document.querySelector('.p_headerSub');
    const WhiteBacks = document.querySelectorAll('.WhiteBack');
    const WhiteBackBoxs = document.querySelectorAll('.WhiteBackBox');
    const FVBox = document.querySelectorAll('.fvArea');
    const Dots = document.querySelectorAll('.p_dot');
    const menuToggle = document.querySelector('.p_headerToggle');
    const Rect = menuToggle.querySelector('.Rect');
    const Text = menuToggle.querySelector('.Text');
    const Close = menuToggle.querySelector('.Close');
    const menuBox = document.querySelector('.p_headerNavBox');
    const modalInner = document.querySelector('.p_headerNavBoxInner');
    const NavBodyBG = document.querySelector('.p_headerNavBodyBG');
    const NavBodySide = document.querySelector('.p_headerNavBoxSide');
    const WhiteLogo = document.querySelector('.WhiteLogo');
    let MenuBool = false;
    function menuEv() {
      if (MenuBool) {
        return false;
      }
      MenuBool = true;
      setTimeout(() => {
        MenuBool = false;
      }, 1100);
      if (menuBox.classList.contains('Act') == true) {
        enableBodyScroll(modalInner);
        menuBox.classList.add('Hide');
        Rect.style.opacity = 1;
        Text.style.opacity = 1;
        Close.style.opacity = 0;
        setTimeout(() => {
          menuBox.classList.remove('Hide');
          menuBox.classList.remove('Act');
        }, 1000);
      } else {
        disableBodyScroll(modalInner);
        menuBox.classList.add('Act');
        Rect.style.opacity = 0;
        Text.style.opacity = 0;
        Close.style.opacity = 1;
      }

      Logo.classList.toggle('Act');
      NavSub.classList.toggle('Act');
    }

    menuToggle.addEventListener('click', () => {
      menuEv();
    });

    menuToggle.addEventListener('mouseover', () => {
      // Rect.innerHTML = '<span>MENU</span>';
    });
    menuToggle.addEventListener('mouseout', () => {
      // Rect.innerHTML = '';
    });

    // NavBodyBG.addEventListener('click', () => {
    //   menuEv();
    //   Logo.classList.toggle('Act');
    //   NavSub.classList.toggle('Act');
    // });
    NavBodySide.addEventListener('click', () => {
      menuEv();
    });

    const footer = document.querySelector('.p_footer');
    const header = document.querySelector('.p_header');
    const observerNav = new IntersectionObserver(
      (entries) => {
        // entriesは監視対象すべてが入っているリスト
        for (const e of entries) {
          // isIntersecting プロパティは交差しているかどうかの真偽値
          // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
          if (e.isIntersecting) {
            // console.log(e);
            header.classList.add('Up');
          } else {
            // console.log(e);
            header.classList.remove('Up');
          }
        }
      },
      {
        root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
        // rootMargin: '0% 0% 0% 0%', //rootの周りのマージンの値
        threshold: 0.5, //どの程度表示されたか
      }
    );
    // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
    observerNav.observe(footer);

    let set_position = 0;
    window.addEventListener('scroll', function () {
      if (document.documentElement.scrollTop < 0) {
        Header.classList.remove('Hide');
        return false;
      }
      if (set_position < document.documentElement.scrollTop) {
        Header.classList.add('Hide');
      } else {
        Header.classList.remove('Hide');
      }
      set_position = document.documentElement.scrollTop;
    });

    if (document.querySelector('.WhiteBack')) {
      const observer = new IntersectionObserver(
        (entries) => {
          // entriesは監視対象すべてが入っているリスト
          for (const e of entries) {
            // isIntersecting プロパティは交差しているかどうかの真偽値
            // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
            if (e.isIntersecting) {
              Logo.classList.add('White');
              Nav.classList.add('White');
              NavSub.classList.add('White');
              Dots[0].classList.add('White');
              Dots[1].classList.add('White');
              // console.log(e);
            } else {
              Logo.classList.remove('White');
              Nav.classList.remove('White');
              NavSub.classList.remove('White');
              Dots[0].classList.remove('White');
              Dots[1].classList.remove('White');
              // console.log(e);
            }
          }
        },
        {
          root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
          rootMargin: '0% 0% -100% 0%', //rootの周りのマージンの値
          threshold: 0, //どの程度表示されたか
        }
      );
      // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
      for (let i = 0; i < WhiteBackBoxs.length; i++) {
        observer.observe(WhiteBackBoxs[i]);
      }
    }

    if (document.querySelector('.fvArea')) {
      // WhiteLogo.src = '/assets/images/logo_plm_without.svg';
      if (window.innerWidth > 786 && document.querySelector('.TopPage')) {
        WhiteLogo.src = '/assets/images/logo_plm.svg';
      }
      const observerFV = new IntersectionObserver(
        (entries) => {
          // entriesは監視対象すべてが入っているリスト
          for (const e of entries) {
            // isIntersecting プロパティは交差しているかどうかの真偽値
            // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
            if (e.isIntersecting) {
              if (window.innerWidth > 786 && document.querySelector('.TopPage')) {
                WhiteLogo.src = '/assets/images/logo_plm.svg';
                return false;
              }
              WhiteLogo.src = '/assets/images/logo_plm_without.svg';
            } else {
              if (document.documentElement.scrollTop < 1) {
                return false;
              }
              WhiteLogo.src = '/assets/images/logo_plm.svg';
            }
          }
        },
        {
          root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
          rootMargin: '0% 0% 0% 0%', //rootの周りのマージンの値
          threshold: 0, //どの程度表示されたか
        }
      );
      // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
      for (let i = 0; i < FVBox.length; i++) {
        observerFV.observe(FVBox[i]);
      }
    }
  }
}
