'use strict';
import './structure/adjustVh';
import './structure/adjustViewport';
import InViewEvent from './components/inViewEvent';
import StickyEvent from './components/stickyEvent';
import SwiperEvent from './components/swiperEvent';
import MenuEvent from './components/menuEvent';

import PlayerPageEvent from './components/playerPage';
import AboutPageEvent from './components/aboutPage';
import RecruitMentPageEvent from './components/recruitmentPage';
import TopPageEvent from './components/topPage';
import RosterPageEvent from './components/rosterPage';
import RecruitPageEvent from './components/recruitPage';
import EntryPageEvent from './components/entryPage';

window.addEventListener('pageshow', function (event) {
  if (event.persisted) {
    window.location.reload();
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const inViewEvent = new InViewEvent();
  const stickyEvent = new StickyEvent();
  const swiperEvent = new SwiperEvent();
  const menuEvent = new MenuEvent();

  const topPage = new TopPageEvent();
  const playerPageEvent = new PlayerPageEvent();
  const aboutPageEvent = new AboutPageEvent();
  const recruitMentPageEvent = new RecruitMentPageEvent();
  const recruitPageEvent = new RecruitPageEvent();
  const rosterPageEvent = new RosterPageEvent();
  const entryPageEvent = new EntryPageEvent();
});
