const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
import imagesLoaded from 'imagesloaded';
import Chart from 'chart.js/auto';
import axios from 'axios';
export default class RosterPageEvent {
  myChart;
  modalBool;

  constructor() {
    this.modalBool = true;
    this.init();
  }
  init() {
    if (!document.querySelector('.RosterPage')) return false;

    ////
    jsonGet(this);

    // GETリクエスト（通信）
    async function jsonGet(that) {
      try {
        const res = await axios.get('/assets/scripts/roster.json');
        const items = res.data.rosters; //JSON.parse(JSON.stringify(res.data));

        if (document.querySelector('.RosterENPage')) {
          let itemEN = makeENItemObj(items);
          renderENCards(itemEN, that);
        } else {
          renderCards(items, that);
        }
        if (document.querySelector('.RosterPageCategory')) {
          setCategory();
        }
      } catch (err) {
        console.error(err);
      }
    }

    function makeENItemObj(items) {
      items.sort(compare);

      let UpObj = [];
      let BottomObj = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].uniformnumber == '3') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '9') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '10') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '11') {
          items[i].click = false;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '17') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '18') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else if (items[i].uniformnumber == '30') {
          items[i].click = true;
          UpObj.push(items[i]);
        } else {
          items[i].click = false;
          BottomObj.push(items[i]);
        }
      }

      let result_concat = UpObj.concat(BottomObj);
      return result_concat;
    }
    function compare(a, b) {
      var r = 0;
      if (Number(a.uniformnumber) < Number(b.uniformnumber)) {
        r = -1;
      } else if (Number(a.uniformnumber) > Number(b.uniformnumber)) {
        r = 1;
      }
      return r;
    }

    function renderCards(obj, that) {
      const element = document.querySelector('.c_rosterCardWrap');
      for (let i = 0; i < obj.length; i++) {
        const card = document.createElement('div');
        card.classList.add('c_rosterCard');
        card.dataset.id = obj[i].id;
        card.dataset.type = obj[i].belongType; //'Type1';
        card.dataset.typeB = obj[i].belongTypeB; //'Type1';
        card.dataset.typeC = obj[i].belongTypeC; //'Type1';
        card.onclick = (e) => {
          const id = e.currentTarget.dataset.id;
          that.drawCard(obj, id);
        };
        let ImagePath = '/assets/images/' + obj[i]['imagepath'] + '.png';
        if (!obj[i]['imagepath']) {
          ImagePath = '/assets/images/' + 'roster_null' + '.png';
        }
        const createElement =
          '<p class="m_rosterCardNumber">' +
          obj[i]['uniformnumber'] +
          '</p>' +
          '<div class="m_rosterCardPhoto">' +
          '  <img class="m_image" src="' +
          ImagePath +
          '" alt="" />' +
          '</div>' +
          '<dl class="m_rosterCardBody">' +
          ' <dt>' +
          obj[i]['name'] +
          '</dt>' +
          ' <dd>' +
          obj[i]['belong'] +
          '</dd>' +
          '</dl>';
        card.innerHTML = createElement;
        element.insertAdjacentElement('beforeend', card);

        imagesLoaded(element, function () {
          element.classList.add('View');
        });
      }
    }

    function renderENCards(obj, that) {
      const element = document.querySelector('.c_rosterCardWrap');
      for (let i = 0; i < obj.length; i++) {
        const card = document.createElement('div');
        if (obj[i].click) {
          card.classList.add('c_rosterCard', 'En');
          // card.onclick = (e) => {
          //   const id = e.currentTarget.dataset.id;
          //   // that.drawENCard(obj, id);
          //   alert(id);
          // };
        } else {
          card.classList.add('c_rosterCard', 'UnHover');
        }

        card.dataset.id = obj[i].id;
        card.dataset.type = obj[i].belongType; //'Type1';
        card.dataset.typeB = obj[i].belongTypeB; //'Type1';
        card.dataset.typeC = obj[i].belongTypeC; //'Type1';

        let ImagePath = '/assets/images/' + obj[i]['imagepath'] + '.png';
        if (!obj[i]['imagepath']) {
          ImagePath = '/assets/images/' + 'roster_null' + '.png';
        }

        let linkHead = '';
        let linkClose = '';

        if (obj[i].click) {
          linkHead = '<a href="' + obj[i].linkedIN + '"  target="_blank" rel="noopener">';
        }
        const createElement =
          '<p class="m_rosterCardNumber">' +
          obj[i]['uniformnumber'] +
          '</p>' +
          '<div class="m_rosterCardPhoto">' +
          '  <img class="m_image" src="' +
          ImagePath +
          '" alt="" />' +
          '</div>' +
          '<dl class="m_rosterCardBody En">' +
          ' <dt>' +
          `${obj[i]['nameENB'] ? obj[i]['nameENB'] : obj[i]['nameEN']}` +
          '</dt>' +
          ' <dd>' +
          obj[i]['belongEN'] +
          '</dd>' +
          '</dl>';

        if (obj[i].click) {
          linkClose = '</a>';
        }

        card.innerHTML = linkHead + createElement + linkClose;
        element.insertAdjacentElement('beforeend', card);

        imagesLoaded(element, function () {
          element.classList.add('View');
        });
      }
    }

    function setCategory() {
      const RosterPageCategory = document.querySelector('.RosterPageCategory');
      const RosterPageCategoryTitle = document.querySelector('.RosterPageCategoryTitle');
      const RosterPageCategoryList = document.querySelector('.RosterPageCategoryList');
      const RosterPageCategorys = document.querySelectorAll('.RosterPageCategoryOne');
      const RosterPage = document.querySelector('.RosterPage');
      let Categorys = false;

      RosterPageCategoryTitle.addEventListener('mouseover', () => {
        RosterPageCategoryList.classList.add('Act');
        Categorys = true;
      });

      RosterPageCategoryTitle.addEventListener('click', () => {
        RosterPageCategoryList.classList.toggle('Act');
        Categorys = true;
      });
      RosterPageCategory.addEventListener('mouseleave', () => {
        RosterPageCategoryList.classList.remove('Act');
        Categorys = false;
      });
      RosterPageCategoryList.addEventListener('mouseleave', () => {
        RosterPageCategoryList.classList.remove('Act');
        Categorys = false;
      });
      for (let i = 0; i < RosterPageCategorys.length; i++) {
        RosterPageCategorys[i].addEventListener('click', (e) => {
          const target = e.currentTarget;
          document.querySelector('.RosterPageCategoryOne.Act').classList.remove('Act');
          target.classList.add('Act');
          RosterPageCategoryList.classList.remove('Act');
          sortCard(target.dataset.type);
        });
      }
    }

    const Title = document.querySelector('.RosterPageCategoryTitle');

    function sortCard(type) {
      Title.innerHTML = switchTitle(type);
      if (type === 'All') {
        const ResetCard = document.querySelectorAll('.c_rosterCard.Hide');
        for (let i = 0; i < ResetCard.length; i++) {
          ResetCard[i].classList.remove('Hide');
        }
      } else {
        const ShowCard = document.querySelectorAll('.c_rosterCard[data-type="' + type + '"]');
        const HideCard = document.querySelectorAll('.c_rosterCard:not([data-type="' + type + '"])');
        let HideCardFIx = [];
        let ShowCardSub = [];

        for (let k = 0; k < HideCard.length; k++) {
          if (HideCard[k].dataset.typeB == type) {
            ShowCardSub.push(HideCard[k]);
          } else if (HideCard[k].dataset.typeC == type) {
            ShowCardSub.push(HideCard[k]);
          } else {
            HideCardFIx.push(HideCard[k]);
          }
        }

        for (let i = 0; i < ShowCard.length; i++) {
          ShowCard[i].classList.remove('Hide');
        }
        for (let i = 0; i < ShowCardSub.length; i++) {
          ShowCardSub[i].classList.remove('Hide');
        }
        for (let j = 0; j < HideCardFIx.length; j++) {
          HideCardFIx[j].classList.add('Hide');
        }
      }
    }

    function switchTitle(type) {
      switch (type) {
        case 'Type1':
          return 'コミュニケーション本部 広報部';
        case 'Type2':
          return '事業開発本部 事業開発部';
        case 'Type3':
          return '営業本部 営業部';
        case 'Type4':
          return 'メディア事業本部 メディア事業部';
        case 'Type5':
          return 'メディア事業本部 IT統括部';
        case 'Type6':
          return '管理部';
        case 'Type7':
          return 'メディアライツ部';
        case 'All':
          return 'すべて';
        default:
          return 'すべて';
      }
    }

    // const cards = document.querySelectorAll('.c_rosterCard');
    const Modal = document.querySelector('.RosterModal');
    const CloseButton = document.querySelector('.RosterModalCLose');
    const ModalBg = document.querySelector('.RosterModalBg');
    const modalInner = document.querySelector('.RosterModalInner');
    // for (let i = 0; i < cards.length; i++) {
    //   cards[i].addEventListener('click', () => {
    //     Modal.classList.add('View');
    //     disableBodyScroll(modalInner);
    //     setTimeout(() => {
    //       this.drawRader();
    //     }, 1000);
    //   });
    // }

    CloseButton.addEventListener('click', () => {
      Modal.classList.remove('View');
      setTimeout(() => {
        this.modalBool = true;
        this.destroyRader();
        modalInner.scrollTop = 0;
        enableBodyScroll(modalInner);
      }, 500);
    });
    // Modal.addEventListener('click', () => {
    //   Modal.classList.remove('View');
    //   enableBodyScroll(modalInner);
    //   this.destroyRader();
    // });
    ModalBg.addEventListener('click', () => {
      Modal.classList.remove('View');
      setTimeout(() => {
        this.modalBool = true;
        this.destroyRader();
        modalInner.scrollTop = 0;
        enableBodyScroll(modalInner);
      }, 500);
    });

    this.draeResize();
  }

  drawCard(obj, id) {
    if (!this.modalBool) {
      return false;
    }
    this.modalBool = false;
    const modalInner = document.querySelector('.RosterModalInner');
    disableBodyScroll(modalInner);
    const Modal = document.querySelector('.RosterModal');
    const rosterNumber = document.querySelector('.js-rosterNumber');
    const rosterNameEn = document.querySelector('.js-rosterNameEn');
    const rosterNameJp = document.querySelector('.js-rosterNameJp');
    const rosterNameAct = document.querySelector('.js-rosterNameAct');
    const rosterImage = document.querySelector('.js-rosterImage');
    const rosterData = document.querySelector('.js-rosterData');
    const rosterDataSub = document.querySelector('.js-rosterDataSub');
    let ViewObj = null;
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].id == id) {
        ViewObj = obj[i];
        break;
      }
    }

    rosterNumber.innerHTML = ViewObj.uniformnumber;
    rosterNameEn.innerHTML = ViewObj.nameEN;
    rosterNameJp.innerHTML = ViewObj.name;
    rosterNameAct.innerHTML = ViewObj.belong;
    let ImagePath = '/assets/images/' + ViewObj.imagepath + '.png';
    if (!ViewObj.imagepath) {
      ImagePath = '/assets/images/' + 'roster_null' + '.png';
    }
    rosterImage.setAttribute('src', ImagePath);

    const dataText =
      '❶' +
      ViewObj.birthplace +
      ' ❷' +
      ViewObj.throwHit +
      ' ❸' +
      ViewObj.career +
      ' ❹' +
      ViewObj.appearancesong +
      ' ❺' +
      ViewObj.favoritesport +
      ' ❻' +
      ViewObj.favoriteathlete +
      ' ❼' +
      ViewObj.favoritestadium +
      ' ❽' +
      ViewObj.choosing +
      ' ❾' +
      ViewObj.lastsupper +
      ' ❿' +
      ViewObj.favoritebook +
      ' ⓫' +
      ViewObj.joining +
      ' ⓬' +
      ViewObj.slogan;
    // ' ⓭' +
    // ViewObj.sloganLast;
    rosterData.innerHTML = dataText;
    // rosterDataSub.innerHTML = ViewObj.name;

    let labels = [];
    let data = [];

    for (let j = 0; j < ViewObj.chart.length; j++) {
      labels.push(ViewObj.chart[j].name);
      data.push(ViewObj.chart[j].value);
    }

    Modal.classList.add('View');

    if (labels[0] == ' ') {
      return false;
    }
    setTimeout(() => {
      this.drawRader(labels, data);
    }, 1000);
  }

  drawENCard(obj, id) {
    if (!this.modalBool) {
      return false;
    }
    this.modalBool = false;
    const modalInner = document.querySelector('.RosterModalInner');
    disableBodyScroll(modalInner);
    const Modal = document.querySelector('.RosterModal');
    const rosterNumber = document.querySelector('.js-rosterNumber');
    const rosterNameEn = document.querySelector('.js-rosterNameEn');
    const rosterNameAct = document.querySelector('.js-rosterNameAct');
    const rosterImage = document.querySelector('.js-rosterImage');
    const rosterLinkedIn = document.querySelector('.RosterModalLinkedIn');
    let ViewObj = null;
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].id == id) {
        ViewObj = obj[i];
        break;
      }
    }
    rosterNumber.innerHTML = ViewObj.uniformnumber;
    rosterNameEn.innerHTML = ViewObj.nameENB;
    rosterNameAct.innerHTML = ViewObj.belongEN;

    if (ViewObj.linkedIN) {
      rosterLinkedIn.href = ViewObj.linkedIN;
    } else {
      rosterLinkedIn.href = '';
      rosterLinkedIn.classList.add('None');
    }
    let ImagePath = '/assets/images/' + ViewObj.imagepath + '.png';
    if (!ViewObj.imagepath) {
      ImagePath = '/assets/images/' + 'roster_null' + '.png';
    }
    rosterImage.setAttribute('src', ImagePath);
    Modal.classList.add('View');
  }

  drawRader(labels = ['', '', '', '', ''], data = [5, 5, 5, 5, 5]) {
    var ctx = document.getElementById('rosterChart');
    this.myChart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: labels,
        datasets: [
          {
            label: 'ROSTER',
            data: data,
            borderColor: 'white',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          r: {
            min: 0,
            max: 5,
            backgroundColor: 'rgba(0, 0, 0, 0)',
            grid: {
              color: 'rgba(255, 255, 255, 0.2)',
            },
            // アングルライン
            angleLines: {
              color: 'rgba(255, 255, 255, 0.2)',
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
            // ポイントラベル
            pointLabels: {
              color: 'white',
              backdropColor: 'rgba(0, 0, 0, 0)',
              font: {
                size: 10,
              },
            },
            ticks: {
              color: 'white',
              backdropColor: 'rgba(0, 0, 0, 0)',
              backdropPadding: 5,
              // padding: 10,
              font: {
                size: 10,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  destroyRader() {
    if (this.myChart) {
      this.myChart.destroy();
    }
    this.myChart = null;
  }

  draeResize() {
    window.addEventListener('resize', () => {
      if (this.myChart) {
        this.myChart.resize();
      }
    });
  }
}
