import Rellax from 'rellax';

export default class TopPageEvent {
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.TopPage')) return false;

    var defaultQueryList = {
      mobile: matchMedia('(max-width: 768px'),
      pc: matchMedia('(min-width: 769px)'),
    };

    function routingByBreakpoint(sourceList) {
      for (var i = 0, l = sourceList.length; i < l; i++) {
        var source = sourceList[i];
        if (!(source.dataset.query && defaultQueryList[source.dataset.query] && source.dataset.src))
          continue;
        if (source.dataset.query === 'mobile' && window.innerWidth > 768) {
          continue;
        }
        if (source.dataset.query === 'pc' && window.innerWidth < 768) {
          continue;
        }
        var newSource = document.createElement('source');
        newSource.src = source.dataset.src;
        source.parentElement.appendChild(newSource);
      }
    }
    var elemList = document.getElementsByClassName('routingByBreakpoint');

    for (var i = 0, l = elemList.length; i < l; i++) {
      var sourceList = elemList[i].getElementsByTagName('source');
      routingByBreakpoint(sourceList);
    }

    const rellax = new Rellax('.l_rellax', {
      breakpoints: [375, 786, 787],
    });

    const Circles = document.querySelectorAll('.TopPageServiceCircle');
    const rellaxService = new Rellax('.l_rellaxService', {
      breakpoints: [375, 786, 787],
      // callback: function (positions) {
      //   // console.log(positions);
      //   let s = 0.5 + positions.y * 0.005 * -1;
      //   for (let i = 0; i < Circles.length; i++) {
      //     Circles[i].style.transform = 'scale(' + s + ')';
      //   }
      // },
    });

    function onButtonClick() {
      var elem = document.querySelector('.TopPageServiceCircleWrap');
      var rect = elem.getBoundingClientRect();
      var elemtop = rect.top;
      var scroll = window.scrollY; //スクロール量を取得
      var windowHeight = window.innerHeight; //画面の高さを取得
      var targetPos = elemtop + scroll; //ターゲット要素の位置を取得

      var per = targetPos - windowHeight - scroll;
      var s = 1 + -per / 8000;
      if (per < 0) {
        for (let i = 0; i < Circles.length; i++) {
          Circles[i].style.transform = 'scale(' + s + ')';
        }
      } else {
        for (let i = 0; i < Circles.length; i++) {
          Circles[i].style.transform = 'scale(1)';
        }
      }
      // console.log(targetPos - windowHeight - scroll);
    }
    window.addEventListener('scroll', () => {
      onButtonClick();
    });

    const Nav = document.querySelector('.p_headerMain');
    const TopPageHeadTitles = document.querySelectorAll('.TopPageHeadTitle');

    const observer = new IntersectionObserver(
      (entries) => {
        // entriesは監視対象すべてが入っているリスト
        for (const e of entries) {
          // isIntersecting プロパティは交差しているかどうかの真偽値
          // viewport に交差し、入ったときに isIntersecting === true、出たときに false になる
          if (e.isIntersecting) {
            Nav.classList.add('Top');
            // console.log(e);
          } else {
            Nav.classList.remove('Top');
            // console.log(e);
          }
        }
      },
      {
        root: null, //ターゲットが見えるかどうかを確認するためのビューポート, nullはブラウザビューポート
        rootMargin: '0% 0% 0% 0%', //rootの周りのマージンの値
        threshold: 0, //どの程度表示されたか
      }
    );
    // observe メソッドに監視対象要素を引数として渡すことで監視されるようになります
    for (let i = 0; i < TopPageHeadTitles.length; i++) {
      observer.observe(TopPageHeadTitles[i]);
    }
  }
}
