// import Swiper JS
import Swiper from 'swiper/bundle';

export default class SwiperEvent {
  constructor() {
    this.init();
  }
  init() {
    // .PlayerScheduleSlide
    if (document.querySelector('.PlayerScheduleSlide')) {
      const playerScheduleSlideSwiper = new Swiper('.PlayerScheduleSlide', {
        loop: false,
        speed: 1500,
        spaceBetween: 60,
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
        slidesPerView: 1.3,
        breakpoints: {
          // 768px以上の場合
          768: {
            spaceBetween: 100,
            slidesPerView: 1.6,
          },
        },
        // centeredSlides: true,
        // navigation: {
        //   nextEl: '.PlayerSlideL',
        //   prevEl: '.PlayerSlideR',
        // },
      });
    }

    if (document.querySelector('.DeliveryFlowSlide')) {
      const deliveryFlowSlideSwiper = new Swiper('.DeliveryFlowSlide', {
        loop: false,
        speed: 1500,
        spaceBetween: 60,
        slidesPerView: 1.6,
        breakpoints: {
          // 768px以上の場合
          768: {
            slidesPerView: 2.6,
            spaceBetween: 60,
          },
        },
        // centeredSlides: true,
        // navigation: {
        //   nextEl: '.PlayerSlideL',
        //   prevEl: '.PlayerSlideR',
        // },
      });
    }

    if (document.querySelector('.PlayerSlideBody')) {
      const now = document.querySelector('.PlayerNow');
      const max = document.querySelector('.PlayerMax');
      const bar = document.querySelector('.PlayerBar');
      const speedTime = 700;
      const speedTime2 = 700;
      const delayTime = 6000;
      const IntervalTime = 5;
      const maxLeng = document.querySelectorAll('.PlayerSlideOne').length;
      let Timer;
      let TimerStop;
      let TimerBool = true;

      const bartTimer = () => {
        let barPer = 0;
        Timer = setInterval(() => {
          barPer += (IntervalTime / delayTime) * 100;
          bar.style.width = barPer + '%';
        }, IntervalTime);

        TimerStop = setTimeout(() => {
          stopBar();
        }, delayTime);
      };

      const stopBar = () => {
        if (!TimerBool) {
          return false;
        }
        TimerBool = false;
        clearInterval(Timer);
        clearTimeout(TimerStop);
        Timer = null;
        TimerStop = null;
        bar.style.width = '100%';
        setTimeout(() => {
          bartTimer();
          TimerBool = true;
        }, speedTime2);
      };

      const playerSlideBodySwiper = new Swiper('.PlayerSlideBody', {
        loop: true,
        speed: speedTime,
        spaceBetween: 15,
        autoplay: {
          delay: delayTime,
          disableOnInteraction: false,
        },
        slidesPerView: 1.5,
        breakpoints: {
          // 768px以上の場合
          spaceBetween: 68,
          768: {
            slidesPerView: 1.4,
          },
        },
        // centeredSlides: true,
        on: {
          init: function () {
            // console.log('swiper initialized');
            now.textContent = 1; //playerSlideBodySwiper.realIndex;
            max.textContent = maxLeng;
            bartTimer();
          },
        },
        navigation: {
          nextEl: '.PlayerSlideR',
          prevEl: '.PlayerSlideL',
        },
      });
      playerSlideBodySwiper.on('slideChange', function (s) {
        stopBar();
        now.textContent = playerSlideBodySwiper.realIndex + 1;
      });

      // マウスストーカー要素
      const mouseStalker = document.getElementById('stalker');
      const playerSwipe = document.querySelector('.PlayerSlideBody');

      const scheduleSwipe = document.querySelector('.PlayerScheduleSlide');

      // マウスストーカー要素の位置
      let stalker = {
        x: 0,
        y: 0,
      };
      // マウスの位置
      let mouse = {
        x: 0,
        y: 0,
      };

      playerSwipe.addEventListener('mouseenter', () => {
        mouseStalker.classList.add('View');
      });
      playerSwipe.addEventListener('mouseleave', () => {
        mouseStalker.classList.remove('View');
      });

      if (scheduleSwipe) {
        scheduleSwipe.addEventListener('mouseenter', () => {
          mouseStalker.classList.add('View');
          mouseStalker.classList.add('Blue');
        });
        scheduleSwipe.addEventListener('mouseleave', () => {
          mouseStalker.classList.remove('View');
          mouseStalker.classList.remove('Blue');
        });
      }

      // マウスの動きを監視
      document.addEventListener('mousemove', mousemove);
      update();

      // マウスが動くたびにマウスの位置を保持しておく
      function mousemove(e) {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
      }

      // 更新処理
      function update() {
        // console.log(mouse.x);
        // マウスストーカー要素の位置を更新
        stalker.x += (mouse.x - stalker.x) * 0.1;
        stalker.y += (mouse.y - stalker.y) * 0.1;
        // マウスストーカーの位置を小数点第一位まで四捨五入
        let x = Math.round(stalker.x * 10) / 10;
        let y = Math.round(stalker.y * 10) / 10;
        // マウスストーカー要素のスタイルを更新
        mouseStalker.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
        // mouseStalkerOuter.style.transform = `translate3d(` + x + 'px,' + y + 'px, 0)';
        // ループ
        requestAnimationFrame(update);
      }
    }
  }
}
