import { CountUp } from 'countup.js';
import Chart from 'chart.js/auto';
import lottie from 'lottie-web';
import inView from 'in-view';

export default class RecruitPageEvent {
  myChart;
  constructor() {
    this.init();
  }
  init() {
    if (!document.querySelector('.RecruitPage')) return false;
    const options = {
      duration: 1.8,
      // useEasing: false,
      scrollSpyOnce: true,
      enableScrollSpy: true,
    };

    const countUp1 = new CountUp('js-CountMotion1', 48, options);
    const countUp1sp = new CountUp('js-CountMotion1sp', 48, options);
    // const countUp1a = new CountUp('js-CountMotion1-1', 9, options);
    // const countUp1b = new CountUp('js-CountMotion1-2', 5, options);
    // const countUp1c = new CountUp('js-CountMotion1-3', 39, options);
    const countUp2 = new CountUp('js-CountMotion2', 7, options);
    const countUp3 = new CountUp('js-CountMotion3', 45, options);
    const countUp4 = new CountUp('js-CountMotion4', 3, options);
    const countUp5 = new CountUp('js-CountMotion5', 1, options);
    const countUp6 = new CountUp('js-CountMotion6', 97, options);

    // this.drawRader();
    // this.draeResize();
    const MainPerMovie = document.getElementById('MainPerMovie');
    const MainPerMovieSP = document.getElementById('MainPerMovieSP');
    const animationTivel = lottie.loadAnimation({
      container: MainPerMovie,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/scripts/data.json',
    });
    inView('#MainPerMovie').on('enter', (el) => {
      animationTivel.play();
    });

    const SuitRatio = document.getElementById('SuitRatio');
    const animationTivel2 = lottie.loadAnimation({
      container: SuitRatio,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/scripts/data-suits.json',
    });
    inView('#SuitRatio').on('enter', (el) => {
      animationTivel2.play();
    });

    // const GenderRatio = document.getElementById('GenderRatio');
    // const animationTivel3 = lottie.loadAnimation({
    //   container: GenderRatio,
    //   renderer: 'svg',
    //   loop: false,
    //   autoplay: false,
    //   path: '/assets/scripts/data-gender.json',
    // });
    // inView('#GenderRatio').on('enter', (el) => {
    //   animationTivel3.play();
    // });

    // GenderRatio;
    // #SuitRatio

    const animationTivelSP = lottie.loadAnimation({
      container: MainPerMovieSP,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/assets/scripts/data.json',
    });
    inView('#MainPerMovieSP').on('enter', (el) => {
      animationTivelSP.play();
    });
  }
  draeResize() {
    // const RecruitData = document.querySelector('.RecruitData');
    window.addEventListener('resize', () => {
      if (this.myChart) {
        this.myChart.resize();
      }
      // let s = window.innerWidth / 1600;
      // RecruitData.style.transform = 'scale(' + s + ')';
    });
  }

  drawRader() {
    var ctx = document.getElementById('pieChart');
    this.myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['正社員', '契約社員', '出向', '業務委託'],
        datasets: [
          {
            data: [48, 9, 5, 39],
            backgroundColor: ['#004EA5', '#0055B4', '#0055B4', '#0055B4'],
          },
        ],
      },
      options: {
        responsive: false,
        cutout: 30,
        elements: {
          arc: {
            borderWidth: 1,
          },
        },
        plugins: {
          tooltip: {
            enabled: false, // <-- this option disables tooltips
          },
          legend: {
            display: false,
          },
        },
      },
    });
  }

  destroyRader() {
    this.myChart.destroy();
    this.myChart = null;
  }
}
